import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import StoreIcon from '@mui/icons-material/Store';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import SettingsIcon from '@mui/icons-material/Settings';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';

const AdminSidebar = () => {

  const navstate = useSelector(state => state.backend.navstate);

  return (
    <div className={'sidebar ' + navstate}>
      <div className="inner">
        <div className="top">
          <span className="logo"></span>
        </div>
        <div className="center">
          <ul>
            <li><NavLink to='/'><DashboardIcon className='icon' /><span>Dashboard</span></NavLink></li>
          </ul>
          <p className='title'>Einstellungen</p>
          <ul>
            <li><NavLink to="/shopuebersicht"><StoreIcon className='icon' /><span>Shopübersicht</span></NavLink></li>
            <li><NavLink to="/multishops"><ShareIcon className='icon' /><span>Multishops</span></NavLink></li>
            <li><NavLink to="/userverwaltung"><PeopleAltIcon className='icon' /><span>Userverwaltung</span></NavLink></li>
            <li><NavLink to="/dokumentvorlagen"><DocumentScannerIcon className='icon' /><span>Dokumentvorlagen</span></NavLink></li>
            <li><NavLink to="/printvorlagen"><PrintIcon className='icon' /><span>Printvorlagen</span></NavLink></li>
          </ul>
          <p className='title'>DHL</p>
          <ul>
            <li><NavLink to="/dhlconfig"><SettingsIcon className='icon' /><span>Konfiguration</span></NavLink></li>
            <li><NavLink to="/dhlproduct"><LocalPostOfficeIcon className='icon' /><span>DHL-Produkte</span></NavLink></li>
          </ul>
          <p className='title'>Statistiken</p>
          <ul>
            <li><NavLink to="/auswertung"><TrendingUpIcon className='icon' /><span>Auswertung</span></NavLink></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default AdminSidebar
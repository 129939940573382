import './css/main.scss';
import { useState, useEffect, useRef, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as GlobalStore from './store/backend/actions';
import Sidebar from './theme/Sidebar'
import Topbar from './theme/Topbar'
import AdminSidebar from './theme/admin/AdminSidebar'
import AdminTopbar from './theme/admin/AdminTopbar'
import ErrorBoundary from './components/ErrorBoundary';
import Login from './pages/login/Login';
import { jwtDecode } from 'jwt-decode';
import Einloeseapp from './pages/zusatz/Einloeseapp';

const AdminStartseite = lazy(() => import('./pages/admin/Startseite'));
const AdminShopuebersicht = lazy(() => import('./pages/admin/Shopuebersicht'));
const NeuerShop = lazy(() => import('./pages/admin/subpages/NeuerShop'));
const ShopBearbeiten = lazy(() => import('./pages/admin/subpages/ShopBearbeiten'));
const ShopFreischaltung = lazy(() => import('./pages/admin/subpages/ShopFreischaltung'));
const AdminMultishops = lazy(() => import('./pages/admin/Multishops'));
const NeuerMultiShop = lazy(() => import('./pages/admin/subpages/NeuerMultiShop'));
const MultiShopBearbeiten = lazy(() => import('./pages/admin/subpages/MultiShopBearbeiten'));
const MultiShopFreischaltung = lazy(() => import('./pages/admin/subpages/MultiShopFreischaltung'));
const MultiShopKategorien = lazy(() => import('./pages/admin/subpages/MultiShopKategoriesortierung'));
const ShopDefaultDomains = lazy(() => import('./pages/admin/subpages/ShopDefaultDomains'));
const AdminUserverwaltung = lazy(() => import('./pages/admin/Userverwaltung'));
const NeuerUser = lazy(() => import('./pages/admin/subpages/NeuerUser'));
const UserBearbeiten = lazy(() => import('./pages/admin/subpages/UserBearbeiten'));
const AdminDokumentvorlagen = lazy(() => import('./pages/admin/Dokumentvorlagen'));
const AdminDokumentBearbeiten = lazy(() => import('./pages/admin/subpages/DokumentBearbeiten'));
const AdminNeuesDokument = lazy(() => import('./pages/admin/subpages/NeuesDokument'));

const AdminPrintDokumentvorlagen = lazy(() => import('./pages/admin/PrintDokumentvorlagen'));
const AdminPrintDokumentBearbeiten = lazy(() => import('./pages/admin/subpages/PrintDokumentBearbeiten'));
const AdminNeuesPrintDokument = lazy(() => import('./pages/admin/subpages/NeuesPrintDokument'));

const AdminAuswertung = lazy(() => import('./pages/admin/Auswertung'));


const AdminDHLConfig = lazy(() => import('./pages/admin/DHLConfig'));
const NeueDHLConfig = lazy(() => import('./pages/admin/subpages/NeueDHLConfig'));
const DHLConfigBearbeiten = lazy(() => import('./pages/admin/subpages/DHLConfigBearbeiten'));
const AdminDHLProduct = lazy(() => import('./pages/admin/DHLProduct'));
const NeuesDHLProduct = lazy(() => import('./pages/admin/subpages/NeuesDHLProduct'));
const DHLProductBearbeiten = lazy(() => import('./pages/admin/subpages/DHLProductBearbeiten'));


const Startseite = lazy(() => import('./pages/Startseite'));
const Vorschau = lazy(() => import('./pages/Vorschau'));
const Allgemein = lazy(() => import('./pages/einstellungen/Allgemein'));
const Versandoptionen = lazy(() => import('./pages/einstellungen/Versandoptionen'));
const Zahlungsanbieter = lazy(() => import('./pages/einstellungen/Zahlungsanbieter'));
const Bezahlmittel = lazy(() => import('./pages/einstellungen/Bezahlmittel'));
const Bilder = lazy(() => import('./pages/einstellungen/Bilder'));
const Texte = lazy(() => import('./pages/einstellungen/Texte'));
const EMails = lazy(() => import('./pages/einstellungen/EMails'));
const Kategorien = lazy(() => import('./pages/produkte/Kategorien'));
const Einzelprodukte = lazy(() => import('./pages/produkte/Einzelprodukte'));
const Produktpakete = lazy(() => import('./pages/produkte/Produktpakete'));
const Kontingente = lazy(() => import('./pages/produkte/Kontingente'));
const Aktionscodes = lazy(() => import('./pages/produkte/Aktionscodes'));
const Produktsortierung = lazy(() => import('./pages/produkte/Produktsortierung'));
const Bestellungen = lazy(() => import('./pages/statistiken/Bestellungen'));
const Auswertung = lazy(() => import('./pages/statistiken/Auswertung'));
const Termine = lazy(() => import('./pages/statistiken/Termine'));
const Produkte = lazy(() => import('./pages/statistiken/Produkte'));
const NeuesProdukt = lazy(() => import('./pages/produkte/subpages/NeuesProdukt'));
const ProduktBearbeiten = lazy(() => import('./pages/produkte/subpages/ProduktBearbeiten'));
const NeuesProduktpaket = lazy(() => import('./pages/produkte/subpages/NeuesProduktpaket'));
const ProduktpaketBearbeiten = lazy(() => import('./pages/produkte/subpages/ProduktpaketBearbeiten'));
const BestellungDetails = lazy(() => import('./pages/statistiken/subpages/BestellungDetails'));
const Error404 = lazy(() => import('./pages/Error404'));
const NeuerAktionscode = lazy(() => import('./pages/produkte/subpages/NeuerAktionscode'));
const AktionscodeBearbeiten = lazy(() => import('./pages/produkte/subpages/AktionscodeBearbeiten'));
const Vorortverkauf = lazy(() => import('./pages/optionen/Vorortverkauf'));
const Umtauschprodukt = lazy(() => import('./pages/optionen/Umtauschprodukt'));
const Warenumtausch = lazy(() => import('./pages/optionen/Warenumtausch'));

const Dokumente = lazy(() => import('./pages/einstellungen/Dokumente'));
const DokumentBearbeiten = lazy(() => import('./pages/einstellungen/subpages/DokumentBearbeiten'));
const NeuesDokument = lazy(() => import('./pages/einstellungen/subpages/NeuesDokument'));
const PrintDokumente = lazy(() => import('./pages/einstellungen/Printvorlagen'));
const PrintDokumentBearbeiten = lazy(() => import('./pages/einstellungen/subpages/PrintDokumentBearbeiten'));
const NeuesPrintDokument = lazy(() => import('./pages/einstellungen/subpages/NeuesPrintDokument'));


const Multiaktionscodes = lazy(() => import('./pages/produkte/Multiaktionscodes'));
const NeuerMultiaktionscode = lazy(() => import('./pages/produkte/subpages/NeuerMultiaktionscode'));
const MultiaktionscodeBearbeiten = lazy(() => import('./pages/produkte/subpages/MultiaktionscodeBearbeiten'));

const App = () => {

  const [pageLocation, setPageLocation] = useState();

  const dispatch = useDispatch();

  const darkmode = useSelector(state => state.backend.darkmode);
  const user = useSelector(state => state.backend.user);
  const navstate = useSelector(state => state.backend.navstate);
  const ref = useRef(null);

  const resizingDelayTimer = useRef(null);

  function getClassBySize(size) {
    if (size > 1679) {
      return 'xxl'
    }
    else if (size > 1399 && size < 1680) {
      return 'xl'
    }
    else if (size > 1199 && size < 1400) {
      return 'lg'
    }
    else if (size > 991 && size < 1200) {
      return 'md'
    }
    else if (size > 767 && size < 992) {
      return 'sm'
    }
    else if (size > 0 && size < 768) {
      return 'xs'
    }
    else {
      return 'xxl'
    }
  }

  useEffect(() => {
    const element = ref?.current;
    if (!element) return;
    let startsizeclass = getClassBySize(element.offsetWidth);
    const observer = new ResizeObserver(() => {
      clearTimeout(resizingDelayTimer.current);
      resizingDelayTimer.current = setTimeout(() => {
        element.setAttribute("class", "gw_backendwrapper");
        let sizeClass = getClassBySize(element.offsetWidth);
        element.classList.add(sizeClass);
        if (sizeClass === 'xs' && user) {
          dispatch(GlobalStore.setNavState('closed'))
        }
        else {
          if (startsizeclass !== sizeClass) {
            dispatch(GlobalStore.setNavState('opened'))
          }
        }
      }, 1)
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [ref, dispatch, user])

  if (!user) {
    return <Login />
  }


  // ADMIN
  let tokendata = jwtDecode(user.token);
  if (tokendata.authorities.includes('SYSTEM_ADMIN') && tokendata.consumerNumber === null) {
    dispatch(GlobalStore.setAdminUser(user));
    return (
      <Suspense>
        <BrowserRouter>
          <div className="gw_backendwrapper" ref={ref}>
            <div className={"app" + (darkmode ? ' darkmode' : '')} >
              <AdminSidebar />
              <div className={'mainholder ' + navstate}>
                <AdminTopbar />
                <ErrorBoundary pageLocation={pageLocation} setPageLocation={setPageLocation} >
                  <Routes>
                    <Route path='/' element={<AdminStartseite />}></Route>
                    <Route path='/shopuebersicht' element={<AdminShopuebersicht />}></Route>
                    <Route path='/shops/neuer-shop' element={<NeuerShop />}></Route>
                    <Route path='/shops/shop-bearbeiten/:id' element={<ShopBearbeiten />}></Route>
                    <Route path='/shops/shop-freischaltung/:id' element={<ShopFreischaltung />}></Route>
                    <Route path='/multishops' element={<AdminMultishops />}></Route>
                    <Route path='/multishops/neuer-shop' element={<NeuerMultiShop />}></Route>
                    <Route path='/multishops/shop-bearbeiten/:id' element={<MultiShopBearbeiten />}></Route>
                    <Route path='/multishops/shop-freischaltung/:id' element={<MultiShopFreischaltung />}></Route>
                    <Route path='/multishops/kategoriesortierung/:id' element={<MultiShopKategorien />}></Route>
                    <Route path='/defaultdomains' element={<ShopDefaultDomains />}></Route>
                    <Route path='/userverwaltung' element={<AdminUserverwaltung />}></Route>
                    <Route path='/userverwaltung/neuer-user' element={<NeuerUser />}></Route>
                    <Route path='/userverwaltung/user-bearbeiten/:id' element={<UserBearbeiten />}></Route>
                    <Route path='/dokumentvorlagen' element={<AdminDokumentvorlagen />}></Route>
                    <Route path='/dokumentvorlagen/vorlage-bearbeiten/:kind/:id' element={<AdminDokumentBearbeiten />}></Route>
                    <Route path='/dokumentvorlagen/neue-vorlage/:kind' element={<AdminNeuesDokument />}></Route>
                    <Route path='/printvorlagen' element={<AdminPrintDokumentvorlagen />}></Route>
                    <Route path='/printvorlagen/vorlage-bearbeiten/:kind/:id' element={<AdminPrintDokumentBearbeiten />}></Route>
                    <Route path='/printvorlagen/neue-vorlage/:kind' element={<AdminNeuesPrintDokument />}></Route>
                    <Route path='/auswertung' element={<AdminAuswertung />}></Route>
                    <Route path='/dhlconfig' element={<AdminDHLConfig />}></Route>
                    <Route path='/dhlconfig/neuer-eintrag/' element={<NeueDHLConfig />}></Route>
                    <Route path='/dhlconfig/eintrag-bearbeiten/:id' element={<DHLConfigBearbeiten />}></Route>
                    <Route path='/dhlproduct' element={<AdminDHLProduct />}></Route>
                    <Route path='/dhlproduct/neuer-eintrag/' element={<NeuesDHLProduct />}></Route>
                    <Route path='/dhlproduct/eintrag-bearbeiten/:id' element={<DHLProductBearbeiten />}></Route>

                    <Route path='*' element={<Error404 />}></Route>
                  </Routes>
                </ErrorBoundary>
              </div>
            </div>
          </div>
        </BrowserRouter>
      </Suspense>
    );
  }


  // USER

  let only_orders = false;
  if (tokendata.authorities.includes('ORDER_USER')) {
    only_orders = true;
  }

  let approute = '';
  if (tokendata.authorities.includes('REDEEM')) {
    approute = <Route path='/zusatz/einloeseapp' element={<Einloeseapp />}></Route>
  }

  let appointmentstats = '';
  if (tokendata.features.includes('appointmentBased')) {
    appointmentstats = <Route path='/statistiken/termine' element={<Termine />}></Route>
  }

  let payedComponents = [];

  if (tokendata.features.includes('customTemplates')) {
    payedComponents.push(<Route key="1" path='/einstellungen/dokumente' element={<Dokumente />}></Route>)
    payedComponents.push(<Route key="2" path='/einstellungen/dokumente/dokument-bearbeiten/:kind/:id' element={<DokumentBearbeiten />}></Route>)
    payedComponents.push(<Route key="3" path='/einstellungen/dokumente/neues-dokument/:kind' element={<NeuesDokument />}></Route>)
  }
  if (tokendata.features.includes('onSiteSales')) {
    payedComponents.push(<Route key="4" path='/optionen/vorortverkauf' element={<Vorortverkauf />}></Route>)
  }
  if (tokendata.features.includes('mailTemplate')) {
    payedComponents.push(<Route key="5" path='/einstellungen/emails' element={<EMails />}></Route>)
  }
  if (tokendata.features.includes('exchangeProduct')) {
    payedComponents.push(<Route key="6" path='/optionen/umtauschprodukt' element={<Umtauschprodukt />}></Route>)
    payedComponents.push(<Route key="7" path='/optionen/warenumtausch' element={<Warenumtausch />}></Route>)
  }

  let adminComponents = [];

  if (tokendata.authorities.includes('SYSTEM_ADMIN')) {
    adminComponents.push(<Route key="1" path='/einstellungen/printvorlagen' element={<PrintDokumente />}></Route>)
    adminComponents.push(<Route key="2" path='/einstellungen/printvorlagen/dokument-bearbeiten/:kind/:id' element={<PrintDokumentBearbeiten />}></Route>)
    adminComponents.push(<Route key="3" path='/einstellungen/printvorlagen/neues-dokument/:kind' element={<NeuesPrintDokument />}></Route>)
  }


  return (
    <Suspense>
      <BrowserRouter>
        <div className="gw_backendwrapper" ref={ref}>
          <div className={"app" + (darkmode ? ' darkmode' : '')} >
            <Sidebar />
            <div className={'mainholder ' + navstate}>
              <Topbar />
              <ErrorBoundary pageLocation={pageLocation} setPageLocation={setPageLocation} >
                <Routes>
                  <Route path='/' element={<Startseite />}></Route>
                  {only_orders ?
                    <>
                      <Route path='/statistiken/bestellungen' element={<Bestellungen />}></Route>
                      <Route path='/statistiken/bestellungen/:id' element={<BestellungDetails />}></Route>
                      {approute}
                    </>
                    :
                    <>
                      <Route path='/vorschau' element={<Vorschau />}></Route>
                      <Route path='/einstellungen/allgemein' element={<Allgemein />}></Route>
                      <Route path='/einstellungen/versandoptionen' element={<Versandoptionen />}></Route>
                      <Route path='/einstellungen/zahlungsanbieter' element={<Zahlungsanbieter />}></Route>
                      <Route path='/einstellungen/bezahlmittel' element={<Bezahlmittel />}></Route>
                      <Route path='/einstellungen/bilder' element={<Bilder />}></Route>
                      <Route path='/einstellungen/texte' element={<Texte />}></Route>
                      <Route path='/produkte/kategorien' element={<Kategorien />}></Route>
                      <Route path='/produkte/einzelprodukte' element={<Einzelprodukte />}></Route>
                      <Route path='/produkte/einzelprodukte/neues-produkt' element={<NeuesProdukt />}></Route>
                      <Route path='/produkte/einzelprodukte/produkt-bearbeiten/:id' element={<ProduktBearbeiten />}></Route>
                      <Route path='/produkte/produktpakete/neues-produktpaket' element={<NeuesProduktpaket />}></Route>
                      <Route path='/produkte/produktpakete/produktpaket-bearbeiten/:id' element={<ProduktpaketBearbeiten />}></Route>
                      <Route path='/produkte/produktpakete' element={<Produktpakete />}></Route>
                      <Route path='/produkte/kontingente' element={<Kontingente />}></Route>
                      <Route path='/produkte/aktionscodes' element={<Aktionscodes />}></Route>
                      <Route path='/produkte/aktionscodes/neuer-code' element={<NeuerAktionscode />}></Route>
                      <Route path='/produkte/aktionscodes/code-bearbeiten/:id' element={<AktionscodeBearbeiten />}></Route>
                      <Route path='/produkte/multiaktionscodes' element={<Multiaktionscodes />}></Route>
                      <Route path='/produkte/multiaktionscodes/neuer-code' element={<NeuerMultiaktionscode />}></Route>
                      <Route path='/produkte/multiaktionscodes/code-bearbeiten/:id' element={<MultiaktionscodeBearbeiten />}></Route>
                      <Route path='/produkte/sortierung' element={<Produktsortierung />}></Route>
                      <Route path='/statistiken/bestellungen' element={<Bestellungen />}></Route>
                      <Route path='/statistiken/bestellungen/:id' element={<BestellungDetails />}></Route>
                      <Route path='/statistiken/produkte' element={<Produkte />}></Route>
                      <Route path='/statistiken/auswertung' element={<Auswertung />}></Route>
                      {appointmentstats}
                      {payedComponents}
                      {adminComponents}
                      {approute}
                      <Route path='*' element={<Error404 />}></Route>
                    </>
                  }
                </Routes>
              </ErrorBoundary>
            </div>
          </div>
        </div>
      </BrowserRouter>
    </Suspense>
  );

}

export default App;
